@import '../../../styles/utils/variables.module.scss';
@import '../../../styles/utils/mixins.module.scss';

.overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(27, 27, 27, 0.6);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 999;
   text-align: center;
   @include mobile {
      background: rgba(255, 255, 255, 1);
   }
   .close-btn {
      font-size: xx-large;
      position: absolute;
      top: 4rem;
      right: 4rem;
      color: #ffffff;
      z-index: 999;
      cursor: pointer;
      width: 40px;
      height: 40px;
      @include mobile {
         font-size: large;
         top: 2rem;
         right: 2rem;
         color: $secondaryColor;
      }
   }
   &__img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 90%;
      max-width: 40%;
      box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      object-fit: cover;
      background-color: #ffffff;
      @include mobile {
         max-height: 80%;
         max-width: 90%;
      }
   }
   .chevron-left,
   .chevron-right {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 45%;
      z-index: 999;
      color: white;
      width: 50px;
      height: 50px;
      @include mobile {
         top: 47%;
      }
   }
   .chevron-left {
      left: 0;
      margin-left: 15rem;
      @include mobile {
         margin-left: 5rem;
      }
   }
   .chevron-right {
      right: 0;
      margin-right: 15rem;
      @include mobile {
         margin-right: 5rem;
      }
   }
   .chevron-left svg,
   .chevron-right svg {
      width: 50px;
      height: 50px;
      color: white;
   }
}
