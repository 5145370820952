@import '../../styles/utils/variables.module.scss';
@import '../../styles/utils/mixins.module.scss';

.footer {
   display: flex;
   position: relative;
   flex-direction: column;
   align-items: center;
   // border-top: 1px solid #ccc;
   padding: 2rem 0 1.5rem;
   bottom: 0;
   width: 100%;
   background-color: white;
   @include mobile {
      padding: 0;
   }
   &__text {
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      @include mobile {
         text-align: center;
      }
   }
   .watercolor-background {
      width: 100%;
      @include mobile {
         height: 200px;
      }
   }
   .social-media {
      display: flex;
      position: absolute;
      bottom: 20%;
      width: 100%;
      align-items: center;
      justify-content: center;
      @include mobile {
         bottom: 30%;
      }
      &__icon {
         font-size: 3rem;
         z-index: 1;
         color: white;
         margin: 1rem;
         @include mobile {
            font-size: 2rem;
         }
      }
   }
}
