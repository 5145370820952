@import 'presentation/styles/utils/variables.module.scss';
@import 'presentation/styles/utils/mixins.module.scss';

@font-face {
   font-family: 'Hello';
   src: local('Hello'), url('./assets/fonts/Hello.ttf') format('truetype');
   /* font-weight: bold; */
}

body {
   font-family: 'Zen Kaku Gothic New', sans-serif;
   font-weight: light;
   font-size: 1.2rem;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   display: flex;
   margin: 0;
   flex-direction: column;
   min-height: 100vh;
   width: 100%;
   max-width: 100%;
   color: $primaryColor;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.main {
   flex: 1;
   min-height: 100vh;
}

#root {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   margin: 0 auto;
}

main {
   display: flex;
   flex-direction: column;
   justify-content: center;
   height: auto;
   flex-grow: 1;
}

h2 {
   color: $secondaryColor;
   font-size: 4rem;
   font-weight: 400;
   font-family: 'Hello', sans-serif;
   margin: 0.5rem 0;
   @include mobile {
      font-size: 3rem;
   }
}
