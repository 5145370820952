@import '../../../styles/utils/variables.module.scss';
@import '../../../styles/utils/mixins.module.scss';

.card-list {
   margin: 4rem 0;
   @include mobile {
      margin: 0;
      margin-bottom: 3rem;
   }
   &__items {
      // display: flex;
      // flex-wrap: wrap;
      // //justify-content: space-evenly;
      // width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      max-width: 1400px;
      grid-row-gap: 40px;
      grid-column-gap: 40px;
      @include tablet {
         grid-template-columns: repeat(3, 1fr);
      }
      @include mobile {
         display: flex;
         flex-direction: column;
         align-items: center;
      }
   }
}
