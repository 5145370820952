@import '../../../styles/utils/variables.module.scss';
@import '../../../styles/utils/mixins.module.scss';

.img-wrapper {
   max-width: 250px;
   height: 100%;
   max-height: 250px;
   @include mobile {
      width: 320px;
      height: 320px;
   }
   img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
   }
}
