@import './variables.module.scss';

@mixin mobile {
   @media screen and (max-width: map-get($breakpoints, mobile)) {
      @content;
   }
}

@mixin tablet {
   @media screen and (max-width: map-get($breakpoints, tablet)) {
      @content;
   }
}

// @mixin desktop {
//    @media screen and (min-width: map-get($map: $breakpoints, $key:desktop)) {
//       @content;
//    }
// }

@mixin larger-desktop {
   @media screen and (min-width: map-get($map: $breakpoints, $key:larger-desktop)) {
      @content;
   }
}

@mixin button {
   border: none;
   background-color: $secondaryColor;
   color: white;
   padding: 1rem;
   font-size: 1.2rem;
   border-radius: 5px;
   margin: 3rem 0;
   // width: 30%;
   &:hover {
      background-color: $primaryColor;
   }
}
