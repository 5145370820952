@import '../../styles/utils/variables.module.scss';
@import '../../styles/utils/mixins.module.scss';

.header-mobile {
   // position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   //margin: 0 1rem;
   &__logo {
      width: 50%;
   }
   .nav-mobile {
      display: flex;
      .menu-displayed {
         position: fixed;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         background-color: $secondaryColor;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: flex-start;
         z-index: 2;
         text-align: center;
         button {
            border: none;
            position: absolute;
            right: 30px;
            top: 50px;
            background-color: $secondaryColor;
            color: white;
            svg {
               width: 40px;
               height: 40px;
            }
         }
         ul {
            margin-top: 10rem;
            list-style: none;
            text-decoration: none;
            padding: 0;
            li {
               margin: 2rem 0;
               font-size: 2rem;
               a {
                  text-decoration: none;
                  color: white;
               }
            }
         }
      }

      .toggle-btn {
         margin-right: 2rem;
         border: none;
         background-color: white;
         color: $secondaryColor;
         z-index: 2;
         padding: 0;
         cursor: pointer;
         svg {
            width: 40px;
            height: 40px;
         }
      }
   }
}

.header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   //    margin: 1rem 0 1rem 2rem;
   width: 100%;

   //margin: 0.5rem auto;
   // background: linear-gradient(
   //    to left,
   //    rgba(109, 158, 155, 0.7),
   //    rgba(255, 255, 255, 0.2)
   // );

   .homepage-link {
      width: 10%;
      padding: 0.2rem 0;
   }
   &__logo {
      display: flex;
      width: 100%;
      height: 100%;
      margin-left: 1rem;
   }
   .nav {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 5px 20px;
      &__item {
         // color: $primaryColor;
         text-decoration: none;
         margin-right: 2rem;
         font-size: 1.5rem;
         &:hover {
            text-decoration: underline;
         }
      }
   }
}
