@import '../../styles/utils/variables.module.scss';
@import '../../styles/utils/mixins.module.scss';

.details {
   margin: 4rem;
   @include mobile {
      margin: 2rem;
   }
   .prev-page-title {
      display: flex;
      align-items: center;
      .prev-page {
         font-size: 2rem;
         color: $secondaryColor;
         margin-right: 2rem;
      }
   }
   .link-to-portfolio {
      display: block;
      text-decoration: none;
      font-weight: bold;
      color: $primaryColor;
      text-align: center;
      &:hover {
         text-decoration: underline;
      }
   }
}
