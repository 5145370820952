// colors
$primaryColor: #426361;
$secondaryColor: #6d9e9b;
//$color-tertiary: #99e2d0;
$tertiaryColor: #d7ebec;
$bgColor: #e8f4f4;

:export {
   primary-color: $primaryColor;
   secondary-color: $secondaryColor;
   bg-color: $bgColor;
}

//global
$shadow: 10px 10px 10px rgba(#7e7e7e, 0.3);

//responsiveness
$breakpoints: (
   mobile: 768px,
   tablet: 1000px,
   larger-desktop: 1400px,
);
